import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { navigationStore } from '@clean-code/shared/util/util-state';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  imports: [RouterModule, CommonModule, TranslocoModule, ProgressBarModule],
})
export class PageContainerComponent {
  protected navigationService = inject(navigationStore);

  public pageTitle = this.navigationService;

}
